import { Card } from "src/components/Card";
import SoftwareImage from "src/assets/software.jpg";
import ConsultingImage from "src/assets/consulting.jpg";
import SupportImage from "src/assets/support.jpg";
import MobileImage from "src/assets/mobile.jpg";

const Products = () => {
  return (
    <section id="products" className="section bg-white">
      <div className="container mx-auto">
        <h2 className="h2 text-gray-800 font-semibold flex justify-center">
          Produtos
        </h2>
        <div className="flex flex-col lg:flex-row lg:gap-x-20 lg:gap-y-0">
          <div className="flex-1">
            <div className="flex flex-col gap-y-5">
              <Card
                title="Aplicativos Móveis"
                text="Criando experiências e construindo soluções móveis inovadoras para o seu sucesso digital."
                image={MobileImage}
              />
              <Card
                title="Software"
                text="Transformando ideias em soluções digitais de excelência."
                image={SoftwareImage}
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="flex flex-col gap-y-5">
              <Card
                title="Consultoria"
                text="Orientando estrategicamente seu negócio rumo à eficiência tecnológica."
                image={ConsultingImage}
              />
              <Card
                title="Suporte"
                text="Seu sucesso garantido com suporte especializado em T.I."
                image={SupportImage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Products };
