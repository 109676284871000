const useHeader = () => {
  const openNav = () => {
    const drawerNav = document.getElementById("drawerNav");
    if (drawerNav) drawerNav.style.width = "250px";
  };

  const closeNav = () => {
    const drawerNav = document.getElementById("drawerNav");
    if (drawerNav) drawerNav.style.width = "0";
  };

  const BUTTONS = [
    {
      title: "Home",
      to: "home",
    },
    {
      title: "Produtos",
      to: "products",
    },
    {
      title: "Sobre Nós",
      to: "about",
    },
  ];

  return { openNav, closeNav, BUTTONS };
};

export { useHeader };
