import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { BsArrowDownSquare } from "react-icons/bs";

import { fadeIn } from "src/variants";
import { Header } from "src/components/Header";

const Banner = () => {
  return (
    <section
      id="home"
      className="section-banner bg-banner bg-no-repeat bg-cover"
    >
      <div className="h-[100vh] w-[100vw] flex justify-center items-center bg-black/10">
        <Header />
        <div className="container mx-auto">
          <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12">
            <div className="lg:text-left">
              <motion.div
                variants={fadeIn("right", 0.4)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true }}
                className="bg-black/40 p-6 rounded-lg"
              >
                <p className="text-[36px] lg:text-[45px] font-semibold leading-[1] mr-4 mb-4">
                  Liderando com tecnologia.
                  <br />
                  Impulsionando seu sucesso digital.
                </p>
                <p className="text-[18px] lg:text-[24px] font-semibold leading-[1]">
                  Descubra o nosso universo e transforme suas ideias em
                  realidade.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <motion.div
        variants={fadeIn("up", 4)}
        initial="hidden"
        whileInView={"show"}
        className="absolute bottom-3"
      >
        <Link
          className="seta hover:cursor-pointer"
          activeClass="bg-transparent"
          to="products"
          smooth={true}
          spy={true}
        >
          Saiba mais
          <BsArrowDownSquare size={50} />
        </Link>
      </motion.div>
    </section>
  );
};

export { Banner };
