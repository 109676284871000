import { Content } from "./content";

const Privacy = () => {
  return (
    <div className="overflow-hidden">
      <Content />
    </div>
  );
};

export default Privacy;
