import { motion } from "framer-motion";

import { fadeIn } from "src/variants";

const About = () => {
  return (
    <section id="about" className="section bg-black/70">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0">
          <motion.div
            variants={fadeIn("bottom", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
            className="flex-1"
          >
            <h2 className="h2 text-white font-semibold flex justify-center">
              Sobre nós
            </h2>
            <div className="text-white text-justify">
              No mundo em constante evolução da tecnologia, onde cada vez mais
              empresas buscam inovação e soluções digitais, nasceu a J&A
              Software Development. Movidos pela paixão pela tecnologia e pelo
              desejo de fazer a diferença, nossa empresa surgiu com um propósito
              claro: liderar com tecnologia e impulsionar o sucesso digital de
              nossos clientes.
              <br />
              Compreendemos que a tecnologia é a força motriz por trás de muitos
              negócios bem-sucedidos nos dias de hoje. É um instrumento poderoso
              que pode transformar processos, otimizar operações e abrir portas
              para oportunidades sem precedentes. Foi com essa visão em mente
              que reunimos uma equipe talentosa e apaixonada, pronta para
              enfrentar desafios e entregar soluções de software excepcionais.
              <br />
              Na J&A Software Development, acreditamos que a liderança vai além
              de apenas fornecer tecnologia de ponta. Nós nos esforçamos para
              entender profundamente as necessidades e objetivos de nossos
              clientes, colaborando de perto para criar soluções personalizadas
              que realmente impulsionem seu sucesso digital. Sabemos que cada
              negócio é único e, portanto, merece uma abordagem personalizada e
              inovadora.
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("bottom", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
            className="flex-1"
          >
            <div className="text-white text-justify">
              Nossa equipe dedicada possui expertise em diversas áreas, desde
              desenvolvimento de aplicativos móveis e web até soluções de
              software empresarial e integrações complexas. Utilizando as mais
              recentes tecnologias e melhores práticas da indústria, entregamos
              soluções sob medida que oferecem experiências excepcionais aos
              usuários finais, aumentam a eficiência operacional e impulsionam o
              crescimento dos negócios.
              <br />
              Além disso, na J&A Software Development, acreditamos em parcerias
              de longo prazo. Estamos comprometidos em fornecer suporte contínuo
              e manter nossas soluções atualizadas, acompanhando o ritmo
              acelerado da tecnologia. Estamos sempre prontos para enfrentar
              novos desafios e garantir que nossos clientes estejam um passo à
              frente no mercado digital.
              <br />
              Seja você uma startup em busca de um produto inovador, uma empresa
              estabelecida procurando otimizar seus processos ou alguém com uma
              ideia revolucionária, estamos aqui para liderar seu caminho rumo
              ao sucesso digital. Na J&A Software Development, somos
              impulsionados pela paixão pela tecnologia e pela satisfação de ver
              nossos clientes alcançarem resultados extraordinários.
              <br />
              Liderando com tecnologia. Impulsionando seu sucesso digital.
              Junte-se a nós nessa jornada rumo ao futuro digital!
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export { About };
