import { Link } from "react-scroll";
import { BsList, BsXLg } from "react-icons/bs";

import Logo from "src/assets/logo.png";

import { useHeader } from "./useHeader";

const Header = () => {
  const { closeNav, openNav, BUTTONS } = useHeader();
  return (
    <header>
      <div className="flex flex-row justify-between absolute top-0 right-0 left-0 bg-black/50">
        <Link
          className="ml-4 flex items-center hover:cursor-pointer"
          activeClass="bg-transparent"
          to="home"
          smooth={true}
          spy={true}
        >
          <img src={Logo} alt="" className="w-[120px] h-[70px]" />
        </Link>

        <div className="mr-4 flex items-center justify-center">
          <div className="lg:hidden block">
            <div
              className="transform transition-all hover:scale-125 cursor-pointer"
              onClick={openNav}
            >
              <BsList size={40} />
            </div>
          </div>

          <div className="lg:flex flex-row hidden items-center">
            {BUTTONS.map((button) => (
              <Link
                className="ml-4 flex items-center hover:bg-white/20 hover:cursor-pointer p-2 m-2 rounded-lg"
                activeClass="bg-transparent"
                to={button.to}
                smooth={true}
                spy={true}
              >
                {button.title}
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div id="drawerNav" className="lg:hidden block drawerNav">
        <div
          className="closebtn transition-all transform hover:scale-125 cursor-pointer"
          onClick={closeNav}
        >
          <BsXLg size={30} />
        </div>
        {BUTTONS.map((button) => (
          <Link
            className="ml-4 flex items-center hover:bg-white/20 hover:cursor-pointer p-2 m-2 rounded-lg"
            activeClass="bg-transparent"
            to={button.to}
            smooth={true}
            spy={true}
          >
            {button.title}
          </Link>
        ))}
      </div>
    </header>
  );
};

export { Header };
