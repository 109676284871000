import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const Content = () => {
  return (
    <section id="privacy" className="bg-white">
      <div className="p-4 w-[80px]">
        <Link to="/">
          <BsArrowLeft
            size={40}
            color="black"
            className="transform transition-all hover:scale-125"
          />
        </Link>
      </div>
      <div className="container mx-auto pb-12">
        <div className="flex-1">
          <h2 className="h2 text-gray-700 font-semibold flex justify-center">
            Termos de Uso e Política de Privacidade
          </h2>
          <div className="text-gray-700 text-justify">
            Bem-vindo aos nossos Termos de Uso e Política de Privacidade. Nesta
            página, informamos sobre como tratamos suas informações pessoais e
            detalhamos nossas práticas de privacidade. É importante ler
            atentamente esta política antes de utilizar nossos serviços.
            <br /> <br />
            Coleta de Informações
            <br /> <br />
            Nós declaramos que não armazenamos nenhum tipo de informação pessoal
            dos usuários durante o uso de nossos serviços. Isso significa que
            não coletamos dados como nome, endereço, número de telefone, e-mail
            ou quaisquer outras informações que possam identificar pessoalmente
            você.
            <br /> <br />
            Uso de Armazenamento Local
            <br /> <br />
            Nossos serviços são projetados para funcionar apenas com
            armazenamento local no dispositivo, portanto, ao apagar todos os
            dados ou excluir o aplicativo, todos os dados serão perdidos.
            <br /> <br />
            Uso de Informações Pessoais
            <br /> <br />
            Como não coletamos informações pessoais, também não utilizamos essas
            informações para qualquer finalidade. Não compartilhamos, vendemos
            ou transferimos informações pessoais para terceiros.
            <br /> <br />
            Segurança
            <br /> <br />
            Embora não coletemos informações pessoais, implementamos medidas de
            segurança adequadas para proteger quaisquer dados fornecidos pelos
            usuários em nossos sistemas.
            <br /> <br />
            Menores de Idade
            <br /> <br />
            Não há problemas em utilizar nossos serviços. Não coletamos
            informações pessoais de nenhum usuário.
            <br /> <br />
            Alterações na Política de Privacidade
            <br /> <br />
            Reservamo-nos o direito de atualizar ou modificar esta política de
            privacidade a qualquer momento. Recomendamos que você reveja
            periodicamente esta página para se manter informado sobre quaisquer
            alterações.
            <br /> <br />
            Contato
            <br /> <br />
            Se você tiver alguma dúvida ou preocupação sobre esta Política de
            Privacidade ou sobre nossas práticas de privacidade, entre em
            contato conosco por meio do email:
            <br />
            <br />
            <a
              href="mailto:jasoftwaredevelopment@gmail.com"
              className="flex justify-center transform transition-all hover:scale-125"
            >
              jasoftwaredevelopment@gmail.com
            </a>
            <br />
            Ao utilizar nossos serviços, você concorda e aceita nossos Termos de
            Uso e Política de Privacidade. Entendemos a importância da
            privacidade e estamos comprometidos em proteger as informações
            pessoais dos usuários.
          </div>
        </div>
      </div>
    </section>
  );
};

export { Content };
