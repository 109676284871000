import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-scroll";
import Logo from "src/assets/logo.png";

const Footer = () => {
  return (
    <div className="bg-black flex lg:flex-row flex-col gap-y-6 lg:gap-y-0 justify-evenly pb-24 pt-10">
      <div className="flex flex-col items-center">
        Links Úteis
        <div className="mt-6 gap-y-4 flex flex-col items-center">
          <Link activeClass="bg-transparent" to="home" smooth={true} spy={true}>
            <span className="hover:underline cursor-pointer">Home</span>
          </Link>
          <Link
            activeClass="bg-transparent"
            to="products"
            smooth={true}
            spy={true}
          >
            <span className="hover:underline cursor-pointer">Produtos</span>
          </Link>
          <Link
            activeClass="bg-transparent"
            to="about"
            smooth={true}
            spy={true}
          >
            <span className="hover:underline cursor-pointer">
              Sobre a empresa
            </span>
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center">
        Todos direitos reservados - J&A Software {new Date().getFullYear()}
        <img src={Logo} alt="" className="w-[140px] h-[80px]" />
      </div>
      <div className="flex flex-col items-center">
        Contato
        <div className="pt-4 flex flex-col gap-y-4 items-center">
          <div className="flex text-[20px] gap-x-4 max-w-max mx-auto lg:mx-0">
            <a
              href="https://www.linkedin.com/company/ja-software-development/"
              target="_blank"
              rel="noreferrer"
              className="hover:scale-125 transition-all duration-200"
            >
              <FaLinkedin size={35} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
