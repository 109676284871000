import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";

const Content = () => {
  return (
    <section id="privacy" className="bg-white">
      <div className="p-4 w-[80px]">
        <Link to="/">
          <BsArrowLeft
            size={40}
            color="black"
            className="transform transition-all hover:scale-125"
          />
        </Link>
      </div>
      <div className="container mx-auto pb-12">
        <div className="flex-1">
          <h2 className="h2 text-gray-700 font-semibold flex justify-center">
            Termos de Uso e Política de Privacidade
          </h2>
          <div className="text-gray-700 text-justify">
            Bem-vindo a Pet Hood! Ao utilizar nosso aplicativo, você concorda com os seguintes termos e condições:
            <br /><br />
            Coleta e Armazenamento de Informações 
            <br /><br />
            Para criar e gerenciar sua conta no Pet Hood, coletamos e armazenamos as seguintes informações pessoais: nome, endereço de e-mail, senha criptografada, foto de perfil. Essas informações são essenciais para garantir uma experiência personalizada e segura em nosso aplicativo. Também coletamos dados do dispositivo como versão e modelo para fins de melhorar a experiência do usuário fornecendo logs caso ocorra erros no aplicativo.
            <br /><br />
            Pets para Adoção
            <br /><br />
            Ao utilizar o aplicativo para publicar um animal de para adoção, solicitaremos seu número de WhatsApp, permitindo que os possíveis adotantes entrem em contato para  pedir mais informações e/ou agendar o primeiro encontro com o pet.
            <br /><br />
            Restrição de Idade
            <br /><br />
            A adoção de animais de estimação é uma responsabilidade séria. Portanto, solicitamos sua data de nascimento para garantir que apenas adultos com 21 anos ou mais possam realizar adoções por meio da Pet Hood.
            <br /><br />
            Permissões de Acesso
            <br /><br />
            Para que você possa publicar fotos dos pets disponíveis para adoção, a Pet Hood solicitará acesso aos arquivos do seu dispositivo. Da mesma forma, caso queira tirar fotos do pet diretamente no aplicativo, será solicitada permissão para acessar a câmera do seu dispositivo.
            <br /><br />
            Uso Responsável
            <br /><br />
            A Pet Hood se compromete a utilizar suas informações pessoais de forma responsável e segura, em conformidade com as leis aplicáveis de proteção de dados. Seus dados não serão compartilhados com terceiros sem seu consentimento.
            <br /><br />
            Privacidade
            <br /><br />
            Sua privacidade é de extrema importância para nós. Leia nossa Política de Privacidade para entender como suas informações são coletadas, utilizadas e protegidas pela Pet Hood.
            <br /><br />
            Alterações nos Termos de Uso
            <br /><br />
            Reservamos o direito de atualizar ou modificar estes Termos de Uso periodicamente. Notificaremos você sobre quaisquer alterações relevantes e as atualizações entrarão em vigor após a publicação.
            <br /><br />
            Ao usar o Pet Hood, você concorda com estes Termos de Uso. Se tiver alguma dúvida ou preocupação, entre em contato conosco pelo e-mail:
            <br /><br />
            <a
              href="mailto:jasoftwaredevelopment@gmail.com"
              className="flex justify-center transform transition-all hover:scale-125"
            >
              jasoftwaredevelopment@gmail.com
            </a>
            <br /><br />
            Obrigado por fazer parte da comunidade Pet Hood e por ajudar a promover a adoção responsável de animais de estimação! 🐾
            <br /><br />
          </div>
        </div>
      </div>
    </section>
  );
};

export { Content };
