type CardProps = {
  title: string;
  text: string;
  image: string;
};

const Card = ({ image, text, title }: CardProps) => {
  return (
    <div className="lg:max-w-md xl:max-w-5xl max-w-5xl rounded overflow-hidden shadow-lg transform transition-all hover:scale-95">
      <img className="w-full object-cover" src={image} alt={title} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2 text-gray-700">{title}</div>
        <p className="text-gray-700 text-base">{text}</p>
      </div>
    </div>
  );
};

export { Card };
