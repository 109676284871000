import { Footer } from "src/components/Footer";
import { Banner } from "./components/banner";
import { About } from "./components/about";
import { Products } from "./components/products";

const Home = () => {
  return (
    <div className="overflow-hidden">
      <Banner />
      <Products />
      <About />
      <Footer />
    </div>
  );
};

export default Home;
